import request from "@/utils/request";

// 登录
export function mobile(data) {
    return request({
        url: '/usercenter/login/mobile',
        method: 'post',
        data
    })
}
export function logout(data) {
    return request({
        url: '/usercenter/logout',
        method: 'post',
        data
    })
}
//检查用户是否扫码
export function getUserInfo(data) {
    return request({
        url: '/usercenter/getUserInfo',
        method: 'get',
        params: data
    })
}
