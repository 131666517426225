import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/index'),
        redirect: '/home',
        meta: {
            title: '嗨数 | 首页'
        }
    },
    {
        path: '/index',
        component: () => import('@/views/index'),
        meta: {
            title: '嗨数 | 个人中心'
        },
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/home'),
                meta: {
                    title: '嗨数 | 首页'
                }
            },
            {
                path:'/ydcc',
                name: 'ydcc',
                component: () => import('@/views/ydcc'),
                meta: {
                    title: '嗨数 | 云端储存'
                }
            },
            {
                path:'/zscq',
                name: 'zscq',
                component: () => import('@/views/zscq'),
                meta: {
                    title: '嗨数 | 知识产权'
                }
            },
            {
                path:'/kjxp',
                name: 'kjxp',
                component: () => import('@/views/kjxp'),
                meta: {
                    title: '嗨数 | 跨境选品'
                }
            },
            {
                path:'/dshmd',
                name: 'dshmd',
                component: () => import('@/views/dshmd'),
                meta: {
                    title: '嗨数 | 电商黑名单'
                }
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior: () => ({ y: 0 }),
    routes
})

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if(to.meta.title) {
        document.title = to.meta.title
    }
    //1.如果用户访问/login 或者/register 的请求,应该直接放行
    if(to.path === '/home') return next()

    //2.不是访问的登录页面,所以判断用户是否登录. 判断依据token
    // let token = localStorage.getItem("token")

    //3.如果!token  没有值,则执行if之后的操作
    // if(!token) return next("/home")

    //4.如果代码执行到这一行,说明用户已经登录.则放行
    next()
})



export default router
